<script>
  import client from "../../../client";
  import { onMount } from 'svelte';
  import { convertDateTime, convertDate } from "../../../utils/dates";
  import { compareNumberStringDistance } from "../../../utils/strings";
  import Select from 'svelte-select';
  import onScan from "onscan.js";

  $: selected_event, clearSummary();

  let old_event;
  let item_loading = false;
  let events_list = [];
  let final_errors = [];
  let selected_event;
  let infoMessage = ["This will make the event results live and send out the result emails to athletes and race summary email to relevant parties."];
  let sendAthleteResults = true;
  let sendRaceSummary = true;
  let showSummary = false;
  let summaryError = "";
  let summary = {
    total:0,
    totalPrereg:0,
    totalRunners:0,
    totalDnf:0,
    payTotals: {
      Cash: Number(0.00),
      Card: Number(0.00),
      Eft: Number(0.00),
      Online: Number(0.00),
      Total: Number(0.00),
      Complimentaries: Number(0.00),
    },
    complimentaries : {
      Total: 0,
      "Previous Race": 0,
      "Team Member": 0,
      Other: 0,
      Membership: 0,
      "IAC Member": 0,
    }, //Added For Complimentaries Check **complimentary**
    totalDistances: []
  }

  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    listItems();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  function clearSummary() {
    summary = {
      total:0,
      totalPrereg:0,
      totalRunners:0,
      totalDnf:0,
      payTotals: {
        Cash: Number(0.00),
        Card: Number(0.00),
        Eft: Number(0.00),
        Online: Number(0.00),
        Total: Number(0.00),
        Complimentaries: Number(0.00),
      },
      complimentaries : {
        Total: 0,
        "Previous Race": 0,
        "Team Member": 0,
        Other: 0,
        Membership: 0,
        "IAC Member": 0,
      }, //Added For Complimentaries Check **complimentary**
      totalDistances: []
    }
    showSummary = false;
    summaryError = "";
    if (old_event != selected_event) {
      infoMessage = [];
      final_errors = [];
      old_event = selected_event
    }
  }

  async function listItems() {
      item_loading = true;
      let items = await client.service('events').find({
        query: {
          $sort: { start_date: 0 },
          $limit: 25
        }
      });
      
      if (items.total > 0) {
        events_list = [];
        items.data.forEach(i => {
          events_list.push({value:i.id, label: i.name +"-["+ i.venue_name +"]-" + convertDate(i.start_date, 2), activities: i.activities, name: i.name, sheet_name:convertDateTime(i.start_date, 2), event_date: convertDate(i.start_date, 2)})
        });
      } else {
        events_list = [];
      }
      item_loading = false;
  }

  async function finalise() {
    item_loading = true;
    final_errors = [];
    infoMessage = [];
    try {
      let events = await client.service('events').patch(selected_event.value,{
            live : true,
          });

      // console.log(sendAthleteResults);
      // console.log(sendRaceSummary);

      if (sendAthleteResults) {
        let email = await client.service('email').create({
              action : "athleteResults",
              event_id : selected_event.value,
              date_created : convertDateTime(new Date(),2),
              sent : false,
            });
            
        let emailSend = await client.service('email/send').find({
              query : { email : email }
            });    

        if (emailSend.status === "success") {
          infoMessage.push("Race has been finalised.");
        } else {
          final_errors.push({error:emailSend.message});
        }
      }

      if (sendRaceSummary) {
        let email = await client.service('email').create({
              action : "summaryResults",
              event_id : selected_event.value,
              date_created : convertDateTime(new Date(),2),
              sent : false,
            });
            
        let emailSend = await client.service('email/send').create({
              query : { email : email }
            });    

        if (emailSend.status === "success") {
          infoMessage.push("Summary has been finalised.");
        } else {
          final_errors.push({error:emailSend.message});
        }
      }

    } catch(err) {
      console.error(err);
      final_errors = err;
    }
    item_loading = false;
  }

  async function eventSummary() {
    try {
      let race = await client.service('race').find({
        query: {
          event_id: selected_event.value,
        }
      });
      if (race.length > 0) {
        for (let index = 0; index < race.length; index++) {
          const item = race[index];
          ++summary.total;
          if (item.pre_entry) {
            ++summary.totalPrereg;
          }
          if (item.start) {
            ++summary.totalRunners;
            const found = summary.totalDistances.findIndex(element => element.name === item.activity_name);
            if (found > -1) {
              summary.totalDistances[found] = {name:summary.totalDistances[found].name, count: summary.totalDistances[found].count+1};
            } else {
              summary.totalDistances.push({name: item.activity_name, count: 1});
            }
          }

          if (!item.duration && item.start) {
            ++summary.totalDnf;
          }
            /*
            *Complimentaries*
            [0] No
            [1] Previous Race
            [2] Team Member
            [3] Other
            [4] Membership
            [5] IAC Member
            */
          switch (item.comp) {
            case 0:
              item.comp = "No";
              if (item.paid_method != "none")
                summary.payTotals.Total += Number(item.paid_amount);
              switch (item.paid_method) {
                // case 'none':
                //   payTotals.None += item.paid_amount;
                //   break;
                case "cash":
                  summary.payTotals.Cash += Number(item.paid_amount);
                  break;
                case "card":
                  summary.payTotals.Card += Number(item.paid_amount);
                  break;
                case "eft":
                  summary.payTotals.Eft += Number(item.paid_amount);
                  break;
                case "online":
                  summary.payTotals.Online += Number(item.paid_amount);
                  break;
              }
              break;
            case 1:
              summary.payTotals.Complimentaries += Number(item.paid_amount);
              item.comp = "Previous Race";
              summary.complimentaries["Previous Race"]++;
              summary.complimentaries["Total"]++;
              break;
            case 2:
              summary.payTotals.Complimentaries += Number(item.paid_amount);
              item.comp = "Team Member";
              summary.complimentaries["Team Member"]++;
              summary.complimentaries["Total"]++;
              break;
            case 3:
              summary.payTotals.Complimentaries += Number(item.paid_amount);
              item.comp = "Other";
              summary.complimentaries["Other"]++;
              summary.complimentaries["Total"]++;
              break;
            case 4:
              summary.payTotals.Complimentaries += Number(item.paid_amount);
              item.comp = "Membership";
              summary.complimentaries["Membership"]++;
              summary.complimentaries["Total"]++;
              break;  
            case 5:
              summary.payTotals.Complimentaries += Number(item.paid_amount);
              item.comp = "IAC Member";
              summary.complimentaries["IAC Member"]++;
              summary.complimentaries["Total"]++;
              break;  
            // **complimentary**
          }
        }

        // console.log(summary.total);
        // console.log(summary.totalPrereg);
        // console.log(summary.totalRunners);
        // console.log(summary.totalDnf);
        // console.log(summary.payTotals);
        // console.log(summary.complimentaries);
        // console.log(summary.totalDistances);

        summary.totalDistances = summary.totalDistances.sort(compareNumberStringDistance);

        showSummary = true;
      }
    } catch(err) {
      console.error(err);
      summaryError = err;
      showSummary = true;
    }
  }

</script>

<style>
  div.greyGridTable {
  font-family: Arial,Verdana,"Times New Roman", Times, serif;
}
table.greyGridTable {
  border: 2px solid #FFFFFF;
  text-align: center;
  border-collapse: collapse;
  width:100%;
}
table.greyGridTable th {
  border: 1px solid #FFFFFF;
  padding: 5px 4px;
}
table.greyGridTable td {
  border: 1px solid #FFFFFF;
  padding: 5px 4px;
}
table.greyGridTable tbody td {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
table.greyGridTable tbody tr {
  border-top: 2px solid #8B8B8B;
}
table.greyGridTable td:nth-child(even) {
  background: #EBEBEB;
  padding: 5px 10px;
}
table.greyGridTable thead {
  background: #FFFFFF;
  border-bottom: 4px solid #333333;
}
table.greyGridTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  border-left: 2px solid #333333;
}
table.greyGridTable thead th:first-child {
  border-left: none;
}

table.greyGridTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  border-top: 4px solid #333333;
}
table.greyGridTable tfoot td {
  font-size: 14px;
}
</style>

<div class="columns is-multiline">
  <div class="column is-full">
<div class="box">
  <span class="tag is-white is-large">Finalise Race</span>
  {#if item_loading}
    <progress class="progress is-primary" max="100">30%</progress>
  {:else}
    <div class="field" style="margin-top:15px;">
      <label class="label" for="">Select Event</label>
      <div class="control">
        <div class="select is-fullwidth">
          <Select items={events_list} bind:selectedValue={selected_event} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event"></Select>
        </div>
      </div>
    </div>
    {#if selected_event}
    <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => sendAthleteResults = !sendAthleteResults}>
      {#if sendAthleteResults}
        <p class="control">
          <span class="icon">
            <i class="fa fa-check-square fa-2x"></i>
          </span>   
        </p>
        <p class="control" style="margin-left: 10px; font-size: large;"> Send Athlete Results</p>  
        {:else}
        <p class="control">
          <span class="icon">
            <i class="fa fa-square fa-2x"></i>
          </span>   
        </p>
        <p class="control" style="margin-left: 10px; font-size: large;"> Send Athlete Results</p>  
        {/if}
    </div>
    <!-- <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => sendRaceSummary = !sendRaceSummary}>
      {#if sendRaceSummary}
        <p class="control">
          <span class="icon">
            <i class="fa fa-check-square fa-2x"></i>
          </span>   
        </p>
        <p class="control" style="margin-left: 10px; font-size: large;"> Send Race Summary</p>  
        {:else}
        <p class="control">
          <span class="icon">
            <i class="fa fa-square fa-2x"></i>
          </span>   
        </p>
        <p class="control" style="margin-left: 10px; font-size: large;"> Send Race Summary</p>  
        {/if}
    </div>   -->
    {#if infoMessage.length > 0}
    <div class="field" style="margin-top:15px;">
      <div class="notification is-light is-info" style="margin-top:15px;">
        <ul>
        {#each infoMessage as msg}
           <li>{msg}</li>
        {/each}
        </ul>
      </div>  
    </div>
    {/if}
    {/if}
    {#if final_errors.length > 0}
    <div class="notification is-light is-danger" style="margin-top:15px;">
      <ul>
      {#each final_errors as err}
        <li>{err.error}</li>   
      {/each}
      </ul>
    </div>
    {/if}  
    
    {#if selected_event}
      <div style="margin-top:15px;display:inline-box">
        <button type="button" class="button is-primary" on:click={() => finalise()}>Finalise Race</button>
        <button type="Summary" class="button is-primary" on:click={() => eventSummary()}>Race Summary</button>
      </div>
    {/if}

    {#if showSummary}
      {#if summaryError}
        <div class="notification is-light is-danger" style="margin-top:15px;">
          {summaryError}    
        </div>  
      {/if}
    <div class="greyGridTable" style="background-color: #EBEBEB;padding-top:15px;margin-top:15px;padding-bottom:15px;">
      <div style="width: 800px; margin-left: auto;margin-right: auto;background-color: white;padding:20px;">
        <div style="text-align: center;background-color: #EBEBEB;padding:1px; margin-bottom:20px;">
            <h3 style="font-weight:bold;">In-Reach Event Summary</h3>
        </div>
        <div style="padding:0;margin:0;text-align:center">
            <img src="https://results.in-reach.co.za/images/logo-300.jpg" width="300px" alt="logo"/>
            <table class="greyGridTable">
                <thead>
                    <tr>
                        <th colspan="2">Event Totals</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Registered</td>
                        <td>{summary.total}</td>
                    </tr>
                    <tr>
                      <td>Pre-Registrations</td>
                      <td>{summary.totalPrereg}</td>
                    </tr>
                    <tr>
                      <td>Total Started</td>
                      <td>{summary.totalRunners}</td>
                    </tr>
                    <tr>
                      <td>Total DNF</td>
                      <td>{summary.totalDnf}</td>
                    </tr>
                </tbody>
                <tfoot>
                  <tr><td>&nbsp;</td></tr>
                </tfoot>
            </table>
            <table class="greyGridTable">
              <thead>
                  <tr>
                      <th colspan="2">Distance Summary</th>
                  </tr>
              </thead>
              <tbody>
                {#each summary.totalDistances as item}
                <tr>
                  <td>{item.name}</td>
                  <td>{item.count}</td>
                </tr>
                {/each}
              </tbody>
              <tfoot>
                <tr><td>&nbsp;</td></tr>
              </tfoot>
            </table>
            <table class="greyGridTable">
              <thead>
                  <tr>
                      <th colspan="2">Complimentaries</th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total</td>
                  <td>{summary.complimentaries["Total"]}</td>
                </tr>
                <tr>
                  <td>Previous Race</td>
                  <td>{summary.complimentaries["Previous Race"]}</td>
                </tr>
                <tr>
                  <td>Team Member</td>
                  <td>{summary.complimentaries["Team Member"]}</td>
                </tr>
                <tr>
                  <td>Other</td>
                  <td>{summary.complimentaries["Other"]}</td>
                </tr>
                <tr>
                  <td>Membership</td>
                  <td>{summary.complimentaries["Membership"]}</td>
                </tr>
                <tr>
                  <td>IAC Member</td>
                  <td>{summary.complimentaries["IAC Member"]}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr><td>&nbsp;</td></tr>
              </tfoot>
            </table>
            <table class="greyGridTable">
              <thead>
                  <tr>
                      <th colspan="2">Payment Summary</th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash</td>
                  <td>R {summary.payTotals.Cash.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Card</td>
                  <td>R {summary.payTotals.Card.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Eft</td>
                  <td>R {summary.payTotals.Eft.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Online</td>
                  <td>R {summary.payTotals.Online.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style="background-color:#3E3E3E;color:white;">Total</td>
                  <td style="background-color:#3E3E3E;color:white;">R {summary.payTotals.Total.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Complimentaries</td>
                  <td>R {summary.payTotals.Complimentaries.toFixed(2)}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr><td>&nbsp;</td></tr>
              </tfoot>
            </table>
        </div>
      </div>
    </div>
    {/if}
  {/if}
</div>
</div>
</div>