<script>
  import client from "../../../client";
  import { convertDateTimeStringToTime, convertDateTimeHTML5 } from "../../../utils/dates";
  import { validateScanCode } from "../../../utils/strings";
  import { navigateTo, Navigate } from "svelte-router-spa";
  import { onMount } from 'svelte';
  import Clock from "../../../components/Clock.svelte";
  import SvelteTable from "svelte-table";
  import onScan from "onscan.js";
  import {Html5QrcodeScanner} from "html5-qrcode";

  export let currentRoute;
  export let params = {params};

  let html5QrcodeScanner;

  //Register to listen for new registered athletes
  // client.service('race').on("updated", async (race) => {
  //   if (race.event_id === raceEvent.id) {
  //     finishedAthletes();
  //   }
  // });
  client.service('race').on("patched", async (race) => {
    if (race.event_id === raceEvent.id) {
      if (race.status === "finished") {
        finishedAthletes();
      }
    }
  });

  onMount(async () => {
    if (currentRoute.namedParams.id) {
      try {
        raceEvent = await client.service('events').get(parseInt(currentRoute.namedParams.id));
        if (!raceEvent) {
          navigateTo('/');
        } else {
          activities = JSON.parse(raceEvent.activities)
          activities.forEach(act => {
            activitiesSelect.push({label: act.name, value: act.id});
          });
          activitiesSelect = activitiesSelect;
          finishedAthletes();

          //Detect Scan
          /* Remove Scanning */
          if (onScan.isAttachedTo(document)) { 
            onScan.detachFrom(document); 
          }

          // Enable scan events for the entire document
          // if (!onScan.isAttachedTo(document)) {
            onScan.attachTo(document, {
              reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
              onScan: async function(sScancode) { // Alternative to document.addEventListener('scan')
                // console.log("OnScan:",sScancode);
                // if (sScancode.type === "scan") {
                  // var rvRegexp = /TA([0-9]+)/g;
                  try {
                    await client.service('scan').create({
                      event_id: raceEvent.id,
                      // race_number: sScancode.detail.scanCode,
                      race_number: sScancode,
                      scanned: convertDateTimeHTML5(new Date(),2),
                      status: 'scanned'
                    });
                  } catch(err) {
                    console.log("Failed to Save Scan : ", err);
                  }

                  // if (validateScanCode(sScancode.detail.scanCode)) {
                  if (validateScanCode(sScancode)) {
                    // scanCode = sScancode.detail.scanCode;
                    scanCode = sScancode;
                    captureScan();
                  } else {
                    audio.play();
                  }
                // }
              },
              // onKeyDetect: function(iKeyCode){ // output all potentially relevant key events - great for debugging!
              //     console.log('Pressed: ' + iKeyCode);
              // },
              scanError: function(error) {
                console.error(error);
              },
              onPaste: function(epaste, oEvent) {
                if (oEvent.target.id == "scanCode" && oEvent.type == "paste") {
                  scanCode = epaste;
                  captureScan();
                }
              }
            });
          // } else {
          //   console.log("Already Attached");
          // }
          //Detect Scan
          //Camera Scanning
          html5QrcodeScanner = new Html5QrcodeScanner("camreader",{ fps: 30, qrbox: {width: 800, height: 600}, showTorchButtonIfSupported: true },/* verbose= */ false);
          html5QrcodeScanner.render(onCameraScanSuccess, onCameraScanFailure);
        }
      } catch (err) {
        console.error(err);
        navigateTo('/');
      }
    }
  });

  // Page Variables
  let scanCode = "";
  let alert_message;
  let alert_message_type = 'info';
  let athlete_loading = false;
  let raceEvent = { name : ""};
  let activities = [];
  let activitiesSelect = [];
  let showList = 'finished';
  let audio = new Audio('/sounds/SmokeAlarm.mp3');
  let athlete_error = false;
  let lastScan;
  //Update Modal
  let alert_message_update = "";
  let showUpdateAthleteModal = false;
  let update_loading = false;
  let time = { hour:0, minute: 0, second: 0};
  //Filter Modal
  let showResultsFilter = false;
  let filterSelected = {activity:"", gender:"", category:""};
  let filteredList2 = [];
  //Not Finished Modal
  let showNotFinished = false;
  let notFinished = [];
  let nf_loading = false;

  const array = [];
  let list_categories = ["Under 11", "12 to 15","16 to 18","Senior (19-29)","Sub-Veteran (30-39)","Veteran (40-49)","Master (50-59)","Grand-Master (60+)","Great-Grand-Master (70+)", ...array];

  let formModel = {
    index : -1,
    name : "",
    surname : "",
    race_number : "",
    gender : "",
    activity : "",
    duration : "",
    category : ""
  }

  // Finished Athletes
  let items_data = [];
  let items_total = 0;
  let items_limit = 0;
  let items_skip = 0;
  let items_pages = 0;
  let items_page = 0;
  let search = "";

  //Filter Registered Athletes on surname and race_number
  $: filteredList = items_data.filter(item => (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.surname.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.race_number.indexOf(search.toLowerCase()) !== -1));
  $: filterResults(),filterSelected;

  async function onCameraScanSuccess(decodedText, decodedResult) {
  // handle the scanned code as you like, for example:
    console.log(`Code matched = ${decodedText}`, decodedResult);
    if (decodedResult.result.format.formatName == "CODE_128") {
      try {
        await client.service('scan').create({
          event_id: raceEvent.id,
          // race_number: sScancode.detail.scanCode,
          race_number: decodedText,
          scanned: convertDateTimeHTML5(new Date(),2),
          status: 'scanned'
        });
      } catch(err) {
        console.log("Failed to Save Scan : ", err);
      }
      if (validateScanCode(decodedText)) {
        scanCode = decodedText;
        captureScan();
      } else {
        audio.play();
      }
    }

  }

  function onCameraScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    // console.warn(`Code scan error = ${error}`);
  }

  async function finishedAthletes() {
    athlete_loading = true;

    let items = await client.service('race').find({
      query: {
        event_id : raceEvent.id,
        status : "finished",
        $sort: { duration: 0 },
      }
    });

    // console.log(items);
    items_data = items;
    items_total = items.length;
    // items_skip = items.skip;
    // items_pages = Math.floor(items_total/items_limit);
    items_data = items_data;
    athlete_loading = false;
  }

function selectAthlete(row, option) {
  formModel.index = row.id;
  formModel.name = row.name;
  formModel.surname = row.surname;
  formModel.race_number = row.race_number;
  formModel.gender = row.gender;
  formModel.category = row.category;
  activitiesSelect.forEach(act => {
    if (act.value == row.activity_id) {
      formModel.activity = act;
    }
  });
  let tempTime = row.duration.split(":")
  time.hour = tempTime[0];
  time.minute = tempTime[1];
  time.second = tempTime[2];
  showUpdateAthleteModal = true;
  // console.log(formModel);
}

function checkEnter(event) {
  if(event.keyCode === 13){
      event.preventDefault(); // Ensure it is only this code that runs
      if (validateScanCode(scanCode)) {
        captureScan();
      } else {
        audio.play();
      }
  }
}

// function paginate(page) {
//   if (page < 1)
//     page = 0;
//   if (page >= items_pages)  
//     page = items_pages-1;
//   items_skip = (page*items_limit);
//   listItems();
//   items_page = page;
// }

async function updateAthleteRace() {
  try {
    update_loading = true;
    // console.log("Updating Athlete Race");
    let athlete_race = await client.service('race').get(formModel.index);
    // console.log(athlete_race);
    if (athlete_race) {
      // console.log(formModel);
      lastScan = [];
      let status = await client.service('race').patch(athlete_race.id,{
            event_id : athlete_race.event_id,
            activity_id : formModel.activity.value,
            race_number : athlete_race.race_number,
            gender : athlete_race.gender,
            category : athlete_race.category,
            duration : time.hour+":"+time.minute+":"+time.second,
            extra : "update:"+athlete_race.activity_id,
          });
      lastScan = status;
      lastScan.name = formModel.name;
      lastScan.surname = formModel.surname;
      lastScan.activity_name = formModel.activity.label;
      update_loading = false;
      showUpdateAthleteModal = false;
    } else {
      alert_message_update = "Invalid Athlete Race Index";
    }
  } catch(err) {
    console.error(err);
    alert_message_update = err;
    update_loading = false;
  }
}

async function captureScan() {
  try {
    // console.log("Capturing Scan");
    athlete_error = false;
    let athlete_race_list = await client.service('race').find({
        query: {
          event_id: raceEvent.id,
          race_number: scanCode,
          $limit: 1,
        }
      });
    if (athlete_race_list.length > 0) {
      let athlete_race = athlete_race_list[0];
      if (athlete_race.status === 'finished') {
        lastScan = [];
        //Show Already Captured Results.
        lastScan = athlete_race;
        lastScan = lastScan;
        scanCode = "";
      } else {
        if (athlete_race.start) {
          lastScan = [];
          let status = await client.service('race').patch(athlete_race.id,{
            event_id : athlete_race.event_id,
            activity_id : athlete_race.activity_id,
            race_number : athlete_race.race_number,
            gender : athlete_race.gender,
            category : athlete_race.category,
            status : "finished",
            extra : ""
          });
          // console.log(status);
          if (status.duration === null || status.position === null) {
            let status = await client.service('race').get(athlete_race.id);
            lastScan = status;
            lastScan.name = athlete_race.name;
            lastScan.surname = athlete_race.surname;
            lastScan.activity_name = athlete_race.activity_name;
            lastScan = lastScan;
          } else {
            lastScan = status;
            lastScan.name = athlete_race.name;
            lastScan.surname = athlete_race.surname;
            lastScan.activity_name = athlete_race.activity_name;
            lastScan = lastScan;
          }
        } else {
          let new_start;
          for (let index = 0; index < activities.length; index++) {
            const act = activities[index];
            if (act.id == athlete_race.activity_id) {
              new_start = act.start;
              return;
            }
          }
          if (new_start) {
            let status = await client.service('race').patch(athlete_race.id,{
              event_id : athlete_race.event_id,
              activity_id : athlete_race.activity_id,
              race_number : athlete_race.race_number,
              gender : athlete_race.gender,
              category : athlete_race.category,
              status : "finished",
              extra : "",
              start : new_start,
            });
            lastScan = status;
            lastScan.name = athlete_race.name;
            lastScan.surname = athlete_race.surname;
            lastScan.activity_name = athlete_race.activity_name;
            lastScan = lastScan;
          } else {
            athlete_error = true;
            lastScan = [];
            audio.play();
          }
        }
        scanCode = "";
      }
    } else {
      athlete_error = true;
      audio.play();
    }
  } catch(err) {
    athlete_error = true;
    lastScan = [];
    console.error(err);
    audio.play();
  }
}

function filterResults() {
  if (filterSelected.activity) {
    filteredList2 = items_data.filter(item => ((filterSelected.activity)?item.activity_id===filterSelected.activity.value:false));
    if (filterSelected.category) {
      let finalList = [];
      for (let index = 0; index < filterSelected.category.length; index++) {
        const element = filterSelected.category[index];
        let temp = filteredList2.filter(item => (item.category===element))
        finalList = [...finalList, ...temp]
      }
      filteredList2 = finalList;
    }
    if (filterSelected.gender) {
      filteredList2 = filteredList2.filter(item => ((filterSelected.gender)?item.gender===filterSelected.gender:false));
    }

    filteredList2.sort(function (position1, position2) {
      if (position1.position > position2.position) return 1;
      if (position1.position < position2.position) return -1;
    });
  }
}

function resultsFilter() {
  clearFilter();
  showResultsFilter = true;
}

function clearFilter() {
  filterSelected = {activity:"", gender:"", category:""};
  filterSelected = filterSelected;
  filteredList2 = [];
}

async function notFinishedFilter() {
  showNotFinished = true;
  nf_loading = true;
  let items = await client.service('race').find({
    query: {
      event_id : raceEvent.id,
      status : "register",
      finish : null,
      $sort: { duration: 0 },
    }
  });
  notFinished = items;
  nf_loading = false;
}

async function getPhoneNumber(athlete_id) {
  let phoneNumber = await client.service('athlete').get(athlete_id, {
    query: {
      $select: [ 'mobile' ]
    }
  });
  return phoneNumber;
}

const columns = [
    {
      key: "name",
      title: "Name",
      value: v => v.name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "activity_name",
      title: "Activity",
      value: v => v.activity_name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "gender",
      title: "Gender",
      value: v => v.gender,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "category",
      title: "Category",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "duration",
      title: "Time",
      value: v => v.duration,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "race_number",
      title: "Race#",
      value: v => v.race_number,
      sortable: false,
      headerClass: "text-left",
    },
  ];

  const nfcolumns = [
    {
      key: "name",
      title: "Name",
      value: v => v.name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "activity_name",
      title: "Activity",
      value: v => v.activity_name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "gender",
      title: "Gender",
      value: v => v.gender,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "category",
      title: "Category",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "race_number",
      title: "Race#",
      value: v => v.race_number,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "",
      title: "Phone",
      value: v => v.duration,
      sortable: false,
      headerClass: "text-left",
    },
  ];
</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
    font-weight: bold;
    font-size: small;
    padding: 2px;
  }
  div :global(.text-left) {
    text-align: left;
    font-weight: bold;
    font-size: small;
    padding: 2px;
  }

  .small-cell {
    font-size: 0.75rem;
    padding: 2px;
  }
</style>
<div class="columns is-multiline">
  <div class="column is-full">
    <div class="box">
      <div class="columns">
        <div class="column is-5">
          <span class="tag is-dark is-large">{raceEvent.name}</span>
        </div>
        <div class="column is-4">
          {#each activities as act}
          <span class="tag is-light">{act.name}&nbsp;<b>{convertDateTimeStringToTime(act.start)}</b></span>
          {/each}
        </div>
        <div class="column is-2">
          <Navigate
            to="admin/start/{raceEvent.id}"
            styles="button is-small is-info"
          >Start</Navigate>
          <Navigate
            to="admin/register/{raceEvent.id}"
            styles="button is-small is-primary"
          >Register</Navigate>
        </div>
        <div class="column is-1">
          <Clock />
        </div>
      </div>
    </div>
  </div>
  <div class="column is-3">
    <div class="box"> 
      <h6 class="title is-6">Capture</h6>
      <div class="field">
        <div class="control">
          <!-- svelte-ignore missing-declaration -->
          <input class="input is-primary" name="scanCode" id="scanCode" class:is-danger="{athlete_error}" bind:value={scanCode} type="text" placeholder="Race Number" on:keypress={checkEnter}/>
        </div>
      </div>
    </div>
    <div class="box">
      <h6 class="title is-6">Capture Camera</h6>
      <div class="field">
        <div class="control" id="camreader">
        </div>
      </div>
    </div>
    {#if lastScan && !athlete_error}
    <div class="box">
      <h6 class="title is-6">Last Capture</h6>
      <table class="table is-narrow is-fullwidth">
        <tr>
          <th>Name</th><td>{lastScan.name} {lastScan.surname}</td><td></td>
        </tr>
        <tr>
          <th>Race Number</th><td>{lastScan.race_number}</td><td></td>
        </tr>
        <tr>
          <th>Activity</th><td>{lastScan.activity_name}</td><td><span class="tag is-link is-small has-text-weight-bold">{lastScan.position}</span></td>
        </tr>
        <tr>
          <th>Gender</th><td>{lastScan.gender}</td><td><span class="tag is-link is-small has-text-weight-bold">{lastScan.position_gender}</span></td>
        </tr>
        <tr>
          <th>Category</th><td>{lastScan.category}</td><td><span class="tag is-link is-small has-text-weight-bold">{lastScan.position_category}</span></td>
        </tr>
        <tr>
          <th>Time</th><td>{lastScan.duration}</td><td></td>
        </tr>
      </table>
    </div>
    {/if}
    {#if athlete_error}
      <div class="notification is-light is-danger">Error with Scan.  Race Number not found, Athlete has no start time or an Error Occured.  Try re-scanning the card.</div>
    {/if}
  </div>

  <div class="column is-9">
    <div class="box">
      {#if alert_message}
        <div class="notification is-light is-{alert_message_type}">{@html alert_message}</div>
      {/if}
      {#if athlete_loading}
        <progress class="progress is-primary" max="100">30%</progress>
      {:else}
        {#if showList === 'finished'}
          <h6 class="title is-6">Athletes Finished</h6>
          {#if items_data.length > 0}
            <div class="field is-grouped">
              <p class="control">
                {#if items_total > 0}
                <span class="tag is-primary is-medium">Finished : {items_total}</span>
                {/if}
              </p>
              <p class="control is-expanded">
                <input class="input is-small" type="text" bind:value={search} placeholder="Search by Name, Surname, Race Number">
              </p>
              <p class="control">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="button is-warning is-small" on:click={() => search = ''}>
                  Clear
                </a>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="button is-info is-small" on:click={() => resultsFilter()}>
                  Filter
                </a>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="button is-purple is-small" on:click={() => notFinishedFilter()}>
                  Not Finished
                </a>
              </p>
            </div>
            <SvelteTable 
              columns={columns} 
              rows={filteredList} 
              classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
            >
              <tr slot="row" let:row let:n>
                <td style="cursor: pointer;" on:click={() => selectAthlete(row, 'reselect')}>{row.name} {row.surname}</td>
                <td style="cursor: pointer;" on:click={() => selectAthlete(row, 'reselect')}><span class="tag is-link is-small has-text-weight-bold">{row.position}</span> {row.activity_name}</td>
                <td style="cursor: pointer;" on:click={() => selectAthlete(row, 'reselect')}><span class="tag is-link is-small has-text-weight-bold">{row.position_gender}</span> {row.gender}</td>
                <td style="cursor: pointer;" on:click={() => selectAthlete(row, 'reselect')}><span class="tag is-link is-small has-text-weight-bold">{row.position_category}</span> {row.category}</td>
                <td style="cursor: pointer;" on:click={() => selectAthlete(row, 'reselect')}>{row.duration}</td>
                <td style="cursor: pointer;" on:click={() => selectAthlete(row, 'reselect')}>{row.race_number}</td>
              </tr>
            </SvelteTable>  
          {:else}
            <div class="notification is-warning">
              No athletes finished event.
            </div>
          {/if}
        {/if}
      {/if}
    </div>
  </div>
</div>
<div class="modal" class:is-active="{showUpdateAthleteModal}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Update Athlete Race</p>
      <button type="button" class="delete" aria-label="close" on:click={() => showUpdateAthleteModal = false}></button>
    </header>
    <section class="modal-card-body">
      {#if alert_message_update}
        <div class="notification is-light is-danger">{@html alert_message_update}</div>
      {/if}
      <div class="field is-horizontal">
        <div class="field-label is-small">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="input is-primary is-small" disabled value="{formModel.name} {formModel.surname}">
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-small">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Race Num</label>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="input is-primary is-small" disabled value="{formModel.race_number}">
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-small">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Gender</label>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="input is-primary is-small" disabled value="{formModel.gender}">
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-small">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Category</label>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="input is-primary is-small" disabled value="{formModel.category}">
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Activity</label>
        </div>
        <div class="field-body">
          <div class="field">
            <select class="input is-primary" bind:value={formModel.activity} required>
              <option value="" disabled selected>Select Activity</option>
              {#each activitiesSelect as act}
                 <option value={act}>{act.label}</option>
              {/each}
            </select>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Time</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control has-text-centered">
              <input type="number" class="input is-primary" bind:value={time.hour} style="text-align:right;" required min="0" max="999">
              <p class="is-size-7 has-text-weight-bold">Hour</p>
            </div>
            <div class="control has-text-centered">
              <input type="number" class="input is-primary" bind:value={time.minute} style="text-align:right;" required min="0" max="59">
              <p class="is-size-7 has-text-weight-bold">Minute</p>
            </div>
            <div class="control has-text-centered">
              <input type="number" class="input is-primary" bind:value={time.second} style="text-align:right;" required min="0" max="59">
              <p class="is-size-7 has-text-weight-bold">Second</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      {#if !update_loading}
        <button class="button is-success" type="button" on:click={() => updateAthleteRace()}>Update</button>
      {/if}
      <button class="button" type="button" on:click={() => showUpdateAthleteModal = false}>Close</button>
    </footer>
  </div>
</div>

<div class="modal" class:is-active="{showResultsFilter}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Results Filter</p>
      <button type="button" class="delete" aria-label="close" on:click={() => showResultsFilter = false}></button>
    </header>
    <section class="modal-card-body">
      <div class="columns" style="height:135px;">
        <div class="column">
          <div class="field">
              <select class="input is-primary is-small" bind:value={filterSelected.activity} required>
                <option value="" disabled selected>Select Activity</option>
                {#each activitiesSelect as act}
                  <option value={act}>{act.label}</option>
                {/each}
              </select>
          </div>

          {#if filterSelected.activity != ""}
          <div class="field">
            <select class="input is-primary is-small" bind:value={filterSelected.gender} required>
              <option value="" selected>Gender [None]</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-missing-attribute -->
            <a class="button is-warning is-small" on:click={() => clearFilter()}>
              Clear
            </a>
            <span class="is-size-7">Use Ctrl + Click to select multiple.</span>
          </div>
          {/if}
        </div>  
        <div class="column">
          {#if filterSelected.activity != ""}
            <div class="field">
              <div class="select is-multiple is-fullwidth">
              <select class="input is-primary is-small" bind:value={filterSelected.category} multiple required size="4">
                <option value="" disabled selected>Category</option>
                {#each list_categories as cat}
                  <option value={cat}>{cat}</option>
                {/each}
              </select>
              </div>
            </div>
          {/if}
        </div>
      </div>
        
      {#if filteredList2.length > 0}
      <div class="columns" style="margin:0">
      <SvelteTable 
              columns={columns} 
              rows={filteredList2} 
              classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
            >
              <tr slot="row" let:row let:n>
                <td class="small-cell">{row.name} {row.surname}</td>
                <td class="small-cell"><span class="tag is-link is-small has-text-weight-bold">{row.position}</span> {row.activity_name}</td>
                <td class="small-cell"><span class="tag is-link is-small has-text-weight-bold">{row.position_gender}</span> {row.gender}</td>
                <td class="small-cell"><span class="tag is-link is-small has-text-weight-bold">{row.position_category}</span> {row.category}</td>
                <td class="small-cell">{row.duration}</td>
                <td class="small-cell">{row.race_number}</td>
              </tr>
            </SvelteTable>  
          </div>
            {/if}
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" on:click={() => showResultsFilter = false}>Close</button>
    </footer>
  </div>
</div>

<div class="modal" class:is-active="{showNotFinished}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Not Finished</p>
      <button type="button" class="delete" aria-label="close" on:click={() => showNotFinished = false}></button>
    </header>
    <section class="modal-card-body">
      {#if nf_loading}
        <progress class="progress is-primary" max="100">30%</progress>
      {/if}
      {#if notFinished.length > 0 && !nf_loading}
      <div style="margin-bottom: 5px;"><span class="tag is-link has-text-weight-bold">Total : {notFinished.length}</span></div>
      
          <SvelteTable 
              columns={nfcolumns} 
              rows={notFinished} 
              classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
            >
              <tr slot="row" let:row let:n>
                <td class="small-cell">{row.name} {row.surname}</td>
                <td class="small-cell">{row.activity_name}</td>
                <td class="small-cell">{row.gender}</td>
                <td class="small-cell">{row.category}</td>
                <td class="small-cell">{row.race_number}</td>
                <td class="small-cell">
                  {#await getPhoneNumber(row.athlete_id)}
                      Loading...
                  {:then data}
                      {data.mobile}
                  {/await}
                </td>
              </tr>
            </SvelteTable>  
            {:else}
            <div class="notification is-light is-warning">Nothing Found!</div>
    {/if}
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" on:click={() => showNotFinished = false}>Close</button>
    </footer>
  </div>
</div>
