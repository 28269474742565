<script>
  import client from "../../../client";
  import user from "../../../stores/user.js";
  import { onMount } from 'svelte';
  import { convertDateTime, convertDate } from "../../../utils/dates";
  import Select from 'svelte-select';
  import * as xlsx from 'xlsx';
  import onScan from "onscan.js";

  let item_loading = false;
  let events_list = [];
  let export_list = ["Discovery","Compliance"];
  let export_errors = [];
  let selected_event;
  let selected_export;

  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    listItems();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  async function listItems() {
      item_loading = true;
      let items = await client.service('events').find({
        query: {
          $sort: { start_date: 0 },
          $limit: 25
        }
      });
      
      if (items.total > 0) {
        events_list = [];
        items.data.forEach(i => {
          events_list.push({value:i.id, label: i.name +"-["+ i.venue_name +"]-" + convertDate(i.start_date, 2), activities: i.activities, name: i.name, sheet_name:'Sheet1', event_date: convertDate(i.start_date, 2)})
        });
      } else {
        events_list = [];
      }
      item_loading = false;
  }

  async function exportReport() {
    try {
      item_loading = true;
      let race_items = await client.service('race').find({
        query: {
          $and: [
            {event_id : selected_event.value},
            {status : "finished"}
          ]
        }
      });

      let export_data = [];
      
      if (race_items.length > 0) {
        if (selected_export.value == "Discovery") {
          let temp_date = selected_event.event_date.split("-")
          let event_date = temp_date[2]+"-"+temp_date[1]+"-"+temp_date[0].substring(2)
            for (let index = 0; index < race_items.length; index++) {
              const elem = race_items[index];
              
              let athlete
              try {
                athlete = await client.service('athlete').get(elem.athlete_id, {
                  query: {
                    vitality: 1,
                    $select: [ 'id_number' ]
                  }
                });
              } catch(e) {}
              
              if (athlete) {
                const y = athlete.id_number.substr(0, 2),
                      m = athlete.id_number.substr(2, 2),
                      d = athlete.id_number.substr(4, 2);
                export_data.push({
                      "Entity Number": "",
                      "Id No/Passport No": athlete.id_number,
                      "DOB": d + "-" + m + "-" + y,
                      "First Name": elem.name,
                      "Surname": elem.surname,
                      "Full Initials": (elem.name + " " + elem.surname)
                        .replace(/[^a-zA-Z- ]/g, "")
                        .match(/\b\w/g)
                        .join(""),
                      "Gender": elem.gender.substr(0, 1),
                      "Type of Event": "",
                      "Event Name": selected_event.name,
                      "Distance": elem.activity_name.replace(/(^\d+)(.+$)/i, "$1"),
                      "Race No": elem.race_number,
                      "Date Of Event": event_date,
                      "Finish Time": elem.duration,
                });
              }
              
            }
        }

        if (selected_export.value == "Compliance") {
            for (let index = 0; index < race_items.length; index++) {
              const elem = race_items[index];
              let athlete = await client.service('athlete').get(elem.athlete_id, {
                query: {
                  $select: [ 'name','surname','id_number','email','mobile' ]
                }
              });
              export_data.push({
                    "Name": athlete.name,
                    "Surname": athlete.surname,
                    "ID Number": athlete.id_number,
                    "Email": athlete.email,
                    "Phone": athlete.mobile,
                    "Temperature": elem.temperature,
              });
            }
        }
      
        if (export_data.length > 0 ) {
          /* With Own Heading
          //Had to create a new workbook and then add the header
          // let Heading = ["First Column", "Second Column", "Third Column"];
          const ws = XLSX.utils.book_new();
          XLSX.utils.sheet_add_aoa(ws, Heading);
          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, Data, { export_data: 'A2', skipHeader: true });

          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, selected_event.sheet_name);
          xlsx.writeFile(wb, selected_event.label.toString().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")+".xlsx");*/

          const ws = xlsx.utils.json_to_sheet(export_data);
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, selected_event.sheet_name);
          xlsx.writeFile(wb, selected_event.label.toString().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")+"-"+selected_export.value+".xlsx");
        }
      } else {
        export_errors.push({error: "Nothing Found!"});
        export_errors = export_errors;
      }
      item_loading = false;
    } catch(err) {
      export_errors.push({error: err});
      export_errors = export_errors;
      item_loading = false;
    }
  }

  async function monthEndExport() {
    try {
      let perRunner = 5.00;
      let compLabel = ["No","Previous Race","Team Member","Other","Membership","IAC Member"];
      item_loading = true;
      let currentDate = new Date();
      let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      lastDay.setHours(23);
      lastDay.setMinutes(59);
      lastDay.setSeconds(59);
      let prevMonthEvents = await client.service('events').find({
        query: {
          start_date: {
            $gte: convertDateTime(firstDay,2),
            $lte: convertDateTime(lastDay,2)
          },
          $sort: { start_date: 1 },
          $limit: 1000
        }
      });
      
      let totals = [];
      const wb = xlsx.utils.book_new();
      // console.log(prevMonthEvents);

      for (let index = 0; index < prevMonthEvents.total; index++) {
        let export_data = [];
        const e = prevMonthEvents.data[index];
        if (e) {
          // console.log(e);
          let event_total = {Name:e.name+" "+convertDate(e.start_date,2), Total:0.00};
          let racers = await client.service('race').find({
            query: {
                  event_id : e.id,
              }
            });
          for (let index2 = 0; index2 < racers.length; index2++) {
            const e2 = racers[index2];
            export_data.push({
                    "Name": e2.name,
                    "Surname": e2.surname,
                    "Race Number": e2.race_number,
                    "Activity": e2.activity_name,
                    "Pre Entry": e2.pre_entry,
                    "Comp": compLabel[parseInt(e2.comp)],
                    "Amount": e2.paid_amount,
                    "Method": e2.paid_method,
              });
              event_total.Total += perRunner;
          }
          totals.push(event_total);
          const ws = xlsx.utils.json_to_sheet(export_data);
          let sheet_name = e.name.replace("\\","").replace("/","").replace("?","").replace("*","").replace("[","").replace("]","").replace(" ","");
          if (sheet_name.length > 20) {
            sheet_name = sheet_name.substring(0,20);
          }
          xlsx.utils.book_append_sheet(wb, ws, sheet_name+""+convertDate(e.start_date,2).replaceAll(" ",""));
        }
      }
      const ws = xlsx.utils.json_to_sheet(totals);
      xlsx.utils.book_append_sheet(wb, ws, "Totals");

      xlsx.writeFile(wb, "InReachStatement["+convertDate(firstDay,2)+":"+convertDate(lastDay,2)+"].xlsx");
      item_loading = false;
    } catch(err) {
      export_errors.push({error: err});
      export_errors = export_errors;
      item_loading = false;
    }
  }

</script>
<div class="columns is-multiline">
  <div class="column is-full">
<div class="box">
  <span class="tag is-white is-large">Export</span>
  {#if item_loading}
    <progress class="progress is-primary" max="100">30%</progress>
  {:else}
    <div class="field" style="margin-top:15px;">
      <label class="label" for="">Select Event</label>
      <div class="control">
        <div class="select is-fullwidth">
          <Select items={events_list} bind:selectedValue={selected_event} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event"></Select>
        </div>
      </div>
    </div>
    <div class="field" style="margin-top:15px;">
      <label class="label" for="">Select Format</label>
      <div class="control">
        <div class="select is-fullwidth">
          <Select items={export_list} bind:selectedValue={selected_export} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Export Format"></Select>
        </div>
      </div>
    </div>
    {#if export_errors.length > 0}
    <div class="notification is-light is-danger" style="margin-top:15px;">
      <ul>
      {#each export_errors as err}
        <li>{err.error}</li>   
      {/each}
      </ul>
    </div>
    {/if}  
    
    {#if selected_event && selected_export}
      <div style="margin-top:15px;">
        <button type="button" class="button is-primary" on:click={() => exportReport()}>Export Excel</button>
      </div>
    {/if}
    {#if $user.email==="sheldon@mole.co.za" && $user.id===1}
      <div style="margin-top:15px;">
        <button type="button" class="button is-primary" on:click={() => monthEndExport()}>Export Excel Month End</button>
      </div>
    {/if}

  {/if}
</div>
</div>
</div>