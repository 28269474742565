<script>
  export let show;
  export let alert_message = "";
  export let menu_heading;
  export let show_race_count=false;

  function updateShow(option) {
      show = option;
      alert_message = "";
  }
</script>
<nav class="panel">
  <p class="panel-heading">{menu_heading}</p>
  <!-- svelte-ignore a11y-missing-attribute -->
  <a class="panel-block {show === 'add' ? 'has-text-link' : ''}" on:click={() => updateShow('add')}>
    <span class="panel-icon {show === 'add' ? 'has-text-link' : ''}">
      <i class="fa fa-plus-square" aria-hidden="true"></i>
    </span>
    Add / Edit
  </a>
  <!-- svelte-ignore a11y-missing-attribute -->
  <a class="panel-block {show === 'list' ? 'has-text-link' : ''}" on:click={() => updateShow('list')}>
    <span class="panel-icon {show === 'list' ? 'has-text-link' : ''}">
      <i class="fa fa-list" aria-hidden="true"></i>
    </span>
    List
  </a>
  {#if show_race_count}
  <!-- svelte-ignore a11y-missing-attribute -->
  <a class="panel-block {show === 'raceCount' ? 'has-text-link' : ''}" on:click={() => updateShow('raceCount')}>
    <span class="panel-icon {show === 'raceCount' ? 'has-text-link' : ''}">
      <i class="fa fa-flag" aria-hidden="true"></i>
    </span>
    Races Count
  </a>
  {/if}
</nav>
