<script>
  import ItemMenu from "../../../components/Side_Menu.svelte";
  import client from "../../../client";
  import { onMount } from 'svelte';
  import SvelteTable from "svelte-table";
  import Select from 'svelte-select';
  import onScan from "onscan.js";
  
  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    listVenues();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  //Venues for activity
  let venue;
  let venue_list = [];
  
  async function listVenues() {
    item_loading = true;
    let items = await client.service('venues').find({
      query: {
        enabled: true,
        $select: [ 'id', 'name' ],
        $sort: { name: 1 },
        $limit: 500
      }
    });

    venue_list = [];
    items.data.forEach(i => {
      venue_list.push({value:i.id, label: i.name})
    });
    item_loading = false;
  }

  //Page Data
  let show = "list";
  let item = 'Activity';
  let item_message;
  let item_message_type = 'info';
  let item_loading = true;
  
  // List Variables / Functions
  let items_data;
  let items_total = 0;
  let items_limit = 0;
  let items_skip = 0;

  async function listItems() {
    console.log(venue);
    console.log(show);

    if (venue && (show === 'list')) {
      item_loading = true;
      let items = await client.service('activity').find({
        query: {
          venue_id: venue.value,
          $sort: { name: 1 },
          $limit: 25
        }
      });

      if (items.total > 0) {
        items_data = items.data;
        items_total = items.total;
        items_limit = items.limit;
        items_skip = items.skip;
      } else {
        items_data = [];
        items_total = 0;
        items_limit = 0;
        items_skip = 0;
      }
      item_loading = false;
    }
  }

  const columns = [
  {
    key: "name",
    title: "NAME",
    value: v => v.name,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      // use first letter of first_name to generate filter
      let letrs = {};
      rows.forEach(row => {
        let letr = row.name.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.name.charAt(0).toLowerCase()
  },
  {
    key: "enabled",
    title: "ENABLED",
    value: v => v.enabled,
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  },
  {
    key: "",
    title: "ACTION",
    value: "",
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  }
];

// Form Variables / Functions

let formModel = {
  index : -1,
  name : "",
  enabled : true,
}

async function onSubmit() {
  item_message = "";
  item_message_type = "info";
  if (formModel.name.length > 5) {
    var splitStr = formModel.name.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    formModel.name = splitStr.join(' ');
    item_loading = true;
    if (formModel.index == -1) {
      try {
        let status = await client.service('activity').create({
          name: formModel.name,
          enabled: formModel.enabled,
          venue_id: venue.value
        });
        formModel.name = "";
        formModel.enabled = true;
        show = 'list';
        await listItems();
        item_message = item+" added successfully";
        item_message_type = "success";
      } catch(err) {
        item_message = err;
        item_message_type = "danger";
        item_loading = false;
      }
    } else {
      try {
        let status = await client.service('activity').update(formModel.index,{
          name: formModel.name,
          enabled: formModel.enabled,
          venue_id: venue.value
        });
        formModel.name = "";
        formModel.enabled = true;
        formModel.index = -1
        show = 'list';
        await listItems();
        item_message = item+" updated successfully";
        item_message_type = "success";
      } catch(err) {
        console.error(err);
        item_message = err;
        item_message_type = "danger";
        item_loading = false;
      }
    }
  } else {
    item_message = "Name must be more than 5 characters";
    item_message_type = "danger";
  }
}

function selectItem(id, name, enabled) {
  item_message = "";
  item_message_type = "info";
  formModel.index = id;
  formModel.name = name;
  formModel.enabled = enabled;
  show = 'add';
}

function cancelForm() {
  item_message = "";
  item_message_type = "info";
  formModel.name = "";
  formModel.index = -1;
  formModel.enabled = true;
  show = 'list';
}

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
  }
  div :global(.text-left) {
    text-align: left;
  }
</style>

<div class="column is-3">
  <ItemMenu bind:show={show} bind:alert_message={item_message} menu_heading={item}/>
</div>

<div class="column is-9">
  <div class="box">
    {#if item_message}
      <div class="notification is-light is-{item_message_type}">{item_message}</div>
    {/if}
    {#if item_loading}
      <progress class="progress is-primary" max="100">30%</progress>
    {:else}
      {#if show === 'add'}
        <div class="content">
          <h1 class="title">
            {#if formModel.index == -1}
              Add {item}
            {:else}
              Edit {item}
            {/if}
          </h1>
        </div>
        <form
          on:submit|preventDefault="{onSubmit}"
        >
          <div class="field">
            <label class="label" for="venue">Venue</label>
            <div class="control">
              <div class="select is-fullwidth">
                <Select id="venue" items={venue_list} bind:selectedValue={venue} on:select={listItems} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Venue"></Select>
              </div>
            </div>
          </div>
          {#if venue}
          <div class="field">
            <label class="label" for="name">{item} Name</label>
            <div class="control">
              <input class="input is-primary" id="name" bind:value={formModel.name} type="text" placeholder="{item} Name" required minlength="6">
            </div>
          </div>
          <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => formModel.enabled = !formModel.enabled}>
            {#if formModel.enabled}
              <p class="control">
                <span class="icon">
                  <i class="fa fa-check-square fa-2x"></i>
                </span>   
              </p>
              <p class="control" style="margin-left: 10px; font-size: large;"> Enabled</p>  
              {:else}
              <p class="control">
                <span class="icon">
                  <i class="fa fa-square fa-2x"></i>
                </span>   
              </p>
              <p class="control" style="margin-left: 10px; font-size: large;"> Disabled</p>  
              {/if}
          </div>  
          <div class="field is-grouped">
            <div class="control">
              <button id="btn-form-submit" class="button is-primary" type="submit">Save</button>
            </div>
            <div class="control">
              <button type="button" class="button is-primary is-light" on:click={() => cancelForm()}>Cancel</button>
            </div>
          </div>
          {:else}
            <div class="notification is-info">
              Please select Venue    
            </div>
          {/if}
        </form>
      {:else}
        <div class="field">
          <label class="label" for="venue">Venue</label>
          <div class="control">
            <div class="select is-fullwidth">
              <Select id="venue" items={venue_list} bind:selectedValue={venue} on:select={listItems} isClearable={false} containerClasses="is-fullwidth" placeholder="Please Select Venue"></Select>
            </div>
          </div>
        </div>
        {#if venue}
          {#if items_total > 0}
            <SvelteTable columns={columns} rows={items_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
              <tr slot="row" let:row let:n>
                <td>{row.name}</td>
                <td style="text-align: center;">
                  {#if row.enabled}
                    <span class="has-text-success icon">
                      <i class="fa fa-check-square"></i>
                    </span>
                  {:else}
                  <span class="has-text-danger icon">
                    <i class="fa fa-square"></i>
                  </span>
                  {/if}
                </td>
                <td style="text-align: center;">
                  <button class="button is-small is-primary" on:click={() => selectItem(row.id, row.name, row.enabled)}>
                    <span class="icon is-small">
                      <i class="fa fa-edit"></i>
                    </span>
                  </button>
                </td>
              </tr>
            </SvelteTable>  
          {:else}
            <div class="notification is-warning">
              No Activities Loaded for Venue    
            </div>
          {/if}
        {/if}
      {/if}
    {/if}
  </div>
</div>

