<script>
  import client from "../client";
  import { onMount } from "svelte";
  import { Navigate, navigateTo } from "svelte-router-spa";
  import { convertDateTime } from "../utils/dates.js";

  onMount(async () => {
    listEvents();
    countAthletes();
  });

  // Events Variables / Functions
  let events = {
    data: [],
    total: 0,
    items: 0,
    skip: 0,
    loading: false,
  };

  let athletes = {
    total: 0,
    loading: false,
  };

  async function listEvents() {
    try {
      events.loading = true;
      let eventsLoad = await client.service("events").find({
        query: {
          $sort: { start_date: 0 },
          $limit: 20,
        },
      });

      events.data = eventsLoad.data;
      events.total = eventsLoad.total;
      events.items = eventsLoad.items;
      events.skip = eventsLoad.skip;
      events.loading = false;
    } catch(err) {
      console.error(err);
    }
  }

  async function countAthletes() {
    try {
      athletes.loading = true;
      let athletesLoad = await client.service("athlete").find({
        query: {
          $limit: 0,
        },
      });
      athletes.total = athletesLoad.total;
      athletes.loading = false;
    } catch(err) {
      console.error(err);
    }
  }

  export let user;
  let athlete;

</script>

<div class="column is-9">
  <section class="hero is-info welcome is-small">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Hello, {user}.</h1>
        <h2 class="subtitle">I hope you are having a great day!</h2>
      </div>
    </div>
  </section>
  <section class="info-tiles">
    <div class="grid has-text-centered">
      <div class="cell">
        <article class="box box">
          <p class="title">{athletes.total}</p>
          <p class="subtitle">Athletes</p>
        </article>
      </div>
      <div class="cell">
        <article class="box box">
          <p class="title">{events.total}</p>
          <p class="subtitle">Events</p>
        </article>
      </div>
      <div class="cell">
        <article class="box box">
          <p class="title"><i class="fa fa-smile-o"/></p>
          <p class="subtitle">Smile</p>
        </article>
      </div>
      <div class="cell">
        <article class="box box">
          <p class="title"><i class="fa fa-calendar"/></p>
          <p class="subtitle">What's next?</p>
        </article>
      </div>
    </div>
  </section>
  <div class="columns">
    <div class="column is-6">
      <div class="card events-card">
        <header class="card-header">
          <p class="card-header-title">Events</p>
        </header>
        <div class="card-table" style="border-top: 1px solid lightgray">
          <div class="content">
            <table class="table is-fullwidth is-striped">
              <tbody>
                {#each events.data as e}
                <tr>
                  <td width="5%"><i class="fa fa-bell-o"></i></td>
                  <td>{ e.name }</td>
                  <td>{convertDateTime(e.start_date,2)}</td>
                  <td style="text-align: right">
                    <Navigate
                      to="admin/register/{e.id}"
                      styles="button is-small is-primary"
                    >
                      <span class="icon">
                        <i class="fa fa-edit"></i>
                      </span>
                    </Navigate>
                    <Navigate
                      to="admin/start/{e.id}"
                      styles="button is-small is-info"
                    >
                      <span class="icon">
                        <i class="fa fa-flag-checkered"></i>
                      </span>
                    </Navigate>
                    <Navigate
                      to="admin/race/{e.id}"
                      styles="button is-small is-link"
                    >
                      <span class="icon">
                        <i class="fa fa-clock-o"></i>
                      </span>
                    </Navigate>
                    <Navigate
                      to="admin/register2/{e.id}"
                      styles="button is-small is-warning"
                    >
                      <span class="icon">
                        <i class="fa fa-edit"></i>
                      </span>
                    </Navigate>
                  </td>
                </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
        <footer class="card-footer">
          <a href="/admin/events" class="card-footer-item">View All</a>
        </footer>
      </div>
    </div>
    <div class="column is-6">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Athlete Search</p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="field has-addons has-addons-fullwidth">
              <div class="control is-fullwidth">
                <input class="input is-large is-fullwidth" type="text" bind:value={athlete} placeholder="Name, Surname, Email, Mobile, ID Number" />
              </div>
              <div class="control">
                <Navigate
                      to="admin/athletes/?search={athlete}"
                      styles="button is-large is-primary"
                >
                  <span class="icon">
                    <i class="fa fa-search"></i>
                  </span>
                </Navigate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
