<script>
  import user from "../../stores/user";
  import client from "../../client";
  import { navigateTo } from "svelte-router-spa";

  export let currentRoute = {currentRoute};
  export let params = {params};

  let alert_error = "";

  const form_credentials = {
    email: '',
    password: ''
  };

  async function login(credentials) {
    try {
      alert_error = "";
      if (!credentials) {
        let temp = await client.reAuthenticate();
        user.login(temp.user);
        navigateTo('admin');
      } else {
        let temp = await client.authenticate({
          strategy: "local",
          ...credentials,
        });
        user.login(temp.user);
        navigateTo('admin');
      }
    } catch (error) {
      if (credentials) {
        alert_error = error.message;
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }

  async function logout() {
    await client.logout();
    user.reset();
  }

  login();
</script>

<style></style>

<div class="hero is-fullheight is-primary">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="column is-8 is-offset-2">
        <h3 class="title has-text-white">Login</h3>
        <hr class="login-hr" />
        <p class="subtitle has-text-white">
          Please login to see our cool stuff!
        </p>
        <div class="box">
          <div class="box">
            <img src="https://results.in-reach.co.za/images/logo-300.jpg" height="209px" width="209px" alt="logo"/>
          </div>
          <div class="title has-text-grey is-5">
            Please enter your email and password.
          </div>
          <form on:submit|preventDefault="{login(form_credentials)}">
            <div class="field">
              <div class="control">
                <!-- svelte-ignore a11y-autofocus -->
                <input
                  class="input is-large"
                  type="email"
                  placeholder="Email"
                  autofocus=""
                  bind:value={form_credentials.email} name="email"
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input is-large"
                  type="password"
                  placeholder="Password"
                  bind:value={form_credentials.password} name="password"
                />
              </div>
            </div>
            <button class="button is-block is-danger is-large is-fullwidth" type="submit">
              Login
            </button>
          </form>
          {#if alert_error}
            <div class="notification is-light is-danger" style="margin-top:10px;">{@html alert_error}</div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
